<script>
  // props
  export let location_properties;
  console.debug("location_properties", location_properties);

  function cleanupURL(myurl) {
    if (myurl.startsWith("http")) {
      return myurl;
    } else {
      return "https://" + myurl;
    }
  }
</script>

<div class="location_address">
  <div class="title">{location_properties.name_d}</div>
  {#if location_properties.description}
    <div class="description">{location_properties.description}</div>
  {/if}
  <div>
    {#if location_properties.phone}
      <a href={`tel:${location_properties.phone}`}
        >{location_properties.phone}</a
      >
    {/if}
    {#if location_properties.location && location_properties.type !== "hotline"}
      <div>{location_properties.street_nr}</div>
      <div>
        {location_properties.postal_code}{" "}{location_properties.location}
      </div>
    {/if}
  </div>
  <div>
    {#if location_properties.email && location_properties.type !== "hotline"}
      <a
        href="mailto:{location_properties.mail || location_properties.email}"
        rel="noreferrer">E-Mail</a
      ><br />
    {/if}
    {#if location_properties.website}
      <a
        target="_blank"
        href={cleanupURL(location_properties.website)}
        rel="noreferrer">Website</a
      >
    {/if}
  </div>
</div>
