<script>
  import appLess from "./app.less";
  import mapboxCSS from "./v2.10.0_mapbox-gl.css";

  import { onMount } from "svelte";
  import {
    store_locationfinder_env_vars,
    store_region,
    store_expert,
    store_location,
  } from "./lib/store.js";
  import Locationfinder from "./lib/Locationfinder.svelte";
  import ExpertSearch from "./lib/expertsearch/ExpertSearch.svelte";

  // prop
  export let mapbox_token;
  export let mapname;
  export let api_url;
  export let mapbox_zoom;
  export let mapbox_center_lng;
  export let mapbox_center_lat;
  export let mapbox_style;
  export let mapbox_language;
  export let mapbox_show_experts;
  export let show_type_selection;
  export let show_location_search;
  export let show_cards;
  export let expertsearch_api_base_url;
  export let region_mapping;

  let appInitialized;
  let locationfinderNodeRef;
  let expertsearchNodeRef;

  onMount(async () => {
    try {
      const locationfinder_env_vars = {
        mapbox_token: mapbox_token,
        mapname: mapname,
        api_url: api_url,
        mapbox_zoom: parseInt(mapbox_zoom),
        mapbox_center_lng: parseFloat(mapbox_center_lng),
        mapbox_center_lat: parseFloat(mapbox_center_lat),
        mapbox_style: mapbox_style,
        mapbox_language: mapbox_language,
        mapbox_show_experts: mapbox_show_experts,
        show_type_selection: show_type_selection === "true" ? true : false,
        show_location_search: show_location_search === "false" ? false : true,
        show_cards: show_cards === "true" ? true : false,
        expertsearch_api_base_url: expertsearch_api_base_url,
        region_mapping: region_mapping
          ? JSON.parse(region_mapping.replaceAll("'", '"'))
          : {},
      };

      // Initialize store with web component attributes
      if (mapname) {
        $store_locationfinder_env_vars[mapname] = locationfinder_env_vars;
        $store_region[mapname] = null;
        $store_location[mapname] = null;
        $store_expert[mapname] = null;

        // Adjust zoom to mobile
        let mql = window.matchMedia("(max-width: 600px)");
        if (mql.matches) {
          $store_locationfinder_env_vars[mapname]["mapbox_zoom"] =
            $store_locationfinder_env_vars[mapname]["mapbox_zoom"] - 1;
        }
      }
      appInitialized = true;
    } catch (error) {
      console.error(error);
    }
  });
</script>

{#if appInitialized}
  <div class="locationfinder-app-wrapper" bind:this={locationfinderNodeRef}>
    {#if mapbox_token}
      <Locationfinder {mapname} {locationfinderNodeRef} />
    {:else}
      <!-- Please provide Mapbox token. -->
    {/if}
  </div>

  <div class="expertseach-app-wrapper" bind:this={expertsearchNodeRef}>
    {#if expertsearch_api_base_url}
      <ExpertSearch {mapname} {expertsearch_api_base_url} />
    {:else}
      <!-- Please provide expert API URL -->
    {/if}
  </div>

  <svelte:element this="style">{@html appLess}</svelte:element>

  <svelte:element this="style">{@html mapboxCSS}</svelte:element>
{/if}
