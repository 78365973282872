<script>
  // TODO Only import the necessary parts!
  import mapboxgl from "mapbox-gl";
  import { store_locationfinder_env_vars } from "../store";
  import { store_mapboxgl } from "../store";
  import LocationData from "../LocationData.svelte";

  // props
  export let mapname;
  export let locationfinderNodeRef;

  // state
  let map;

  const createMap = (node) => {
    if ($store_locationfinder_env_vars[mapname]?.mapbox_style) {
      const options = {
        container: node,
        style: $store_locationfinder_env_vars[mapname]["mapbox_style"],
        center: [
          $store_locationfinder_env_vars[mapname]["mapbox_center_lng"],
          $store_locationfinder_env_vars[mapname]["mapbox_center_lat"],
        ],
        zoom: $store_locationfinder_env_vars[mapname]["mapbox_zoom"],
        hash: false,
        attributionControl: false,
      };
      mapboxgl.accessToken =
        $store_locationfinder_env_vars[mapname]["mapbox_token"];
      map = new mapboxgl.Map(options);

      map.on("load", () => {
        // Resize the map to its container.
        map.resize();
        console.debug("Map resized.");
      });

      // navigation
      const nav = new mapboxgl.NavigationControl({
        showCompass: false,
      });
      map.addControl(nav, "top-right");

      // Initialize map store
      $store_mapboxgl[mapname] = {
        map: map,
        mapboxgl: mapboxgl,
      };
    }
  };
</script>

<div class="mapboxmap-wrapper">
  <div class="mapboxmap" use:createMap>
    {#if map}
      <LocationData {mapname} {locationfinderNodeRef} />
    {/if}
  </div>
</div>
