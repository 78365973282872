<!-- Fetch locations -->
<script>
  import { onMount } from "svelte";
  import Map from "./general/Map.svelte";
  import SearchResult from "./SearchResult.svelte";
  import {
    store_locations_all,
    store_locations_visible,
    store_locationfinder_env_vars,
  } from "./store.js";

  // props
  export let mapname;
  export let locationfinderNodeRef;

  const api_url_locations = $store_locationfinder_env_vars[mapname]?.api_url
    ? `${$store_locationfinder_env_vars[mapname]?.api_url}/@locationfinder-locations?mapname=${mapname}`
    : undefined;

  onMount(async function () {
    if ($store_locationfinder_env_vars[mapname]?.api_url) {
      await fetch(api_url_locations, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        mode: "cors",
        // cache: 'default',
      })
        .then((response) => response.json())
        .then((locs) => {
          let features = locs.features;
          // Initialize stores
          $store_locations_all[mapname] = features;
          $store_locations_visible[mapname] = features;
        });
    }
  });
</script>

{#if $store_locationfinder_env_vars[mapname]?.api_url}
  <Map {mapname} {locationfinderNodeRef} />
  <SearchResult {mapname} />
{/if}
