import { writable } from 'svelte/store';

// app attributes
export const store_locationfinder_env_vars = writable({});

export const store_message = writable([]);

// map
export const store_mapboxgl = writable({});
export const store_popup = writable({});

// locations
export const store_locations_all = writable({});
export const store_locations_visible = writable({});

// experts
export const store_experts_all = writable({});
export const store_experts_visible = writable({});

// filter
export const store_searchstring_location = writable({});
export const store_searchstring_expert = writable({});
export const store_location = writable({});
export const store_expert = writable({});
export const store_region = writable({});
export const store_map_bounds = writable({});
export const store_type_location = writable({});
export const store_type_expert = writable({});
