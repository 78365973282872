import App from './App.wc.svelte';

const getAllAttributes = el => el
  .getAttributeNames()
  .reduce((obj, name) => ({
    ...obj,
    [name]: el.getAttribute(name)
  }), {})

const app_nodes = document.querySelectorAll('locationfinder-component');

const app = app_nodes.forEach(appnode=> {
  new App({
    // target: null,
    target: appnode,
    props: getAllAttributes(appnode)
  })
});

export default app;
