<script>
  import {
    store_map_bounds,
    store_popup,
    store_searchstring_location,
    store_searchstring_expert,
  } from "./store";
  import MiniScroller from "./MiniScroller.svelte";

  import {
    store_expert,
    store_mapboxgl,
    store_location,
    store_locations_all,
    store_locations_visible,
    store_locationfinder_env_vars,
    store_region,
  } from "./store.js";

  // props
  export let mapname;
  export let locationfinderNodeRef;

  const map = $store_mapboxgl[mapname]["map"];

  $: {
    // Update markers on map according store_locations_visible.
    map?.getSource("mysource")?.setData({
      type: "FeatureCollection",
      features: $store_locations_visible[mapname],
    });
  }

  $: {
    // Location is selected. Now do:
    // Fill popup and add to map if location
    // or remove popup from map if no location.
    const location = $store_location[mapname];
    if (location) {
      let coordinates = location.geometry?.coordinates?.slice();
      let properties = location.properties;

      if (coordinates) {
        const map = $store_mapboxgl[mapname]["map"];
        const increaseZoom = 2;
        map
          .easeTo({
            center: coordinates,
            zoom:
              $store_locationfinder_env_vars[mapname]["mapbox_zoom"] +
                increaseZoom >
              map.getZoom()
                ? $store_locationfinder_env_vars[mapname]["mapbox_zoom"] +
                  increaseZoom
                : map.getZoom(),
          })
          .once("moveend", () => {
            let popup = $store_popup[mapname];
            popup
              .setLngLat(coordinates)
              .setHTML('<div id="mini-scroller"></div>')
              .addTo(map);
            new MiniScroller({
              target: popup.getElement().querySelector("#mini-scroller"),
              props: { location_properties: properties },
            });
            // Scroll map into view.
            locationfinderNodeRef.scrollIntoView({ behavior: "smooth" });
          });
      }
    } else {
      // Remove popup from map.
      $store_popup[mapname] && $store_popup[mapname].remove();
    }
  }

  $: {
    // If region then zoom out
    if ($store_region[mapname] && !$store_location[mapname]) {
      const map = $store_mapboxgl[mapname]["map"];
      map.easeTo({
        center: [
          $store_locationfinder_env_vars[mapname]["mapbox_center_lng"],
          $store_locationfinder_env_vars[mapname]["mapbox_center_lat"],
        ],
        zoom: $store_locationfinder_env_vars[mapname]["mapbox_zoom"],
      });
    }
  }

  map.on("load", () => {
    // Resize the map to its container.
    // map.resize();

    // Set language
    map.setLayoutProperty("country-label", "text-field", [
      "get",
      `name_${$store_locationfinder_env_vars[mapname]?.mapbox_language}`,
    ]);

    // Add a new source from our GeoJSON data and set the
    // 'cluster' option to true. GL-JS will add the point_count property to your source data.
    map.addSource("mysource", {
      type: "geojson",
      // Point to GeoJSON data.
      data: {
        type: "FeatureCollection",
        features: $store_locations_visible[mapname],
      },
      cluster: true,
      clusterMaxZoom: 14, // Max zoom to cluster points on
      clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
    });

    map.addLayer({
      id: "clusters",
      type: "circle",
      source: "mysource",
      filter: ["has", "point_count"],
      // TODO marker color configurable
      paint: {
        // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
        // with three steps to implement three types of circles:
        //   * Blue, 20px circles when point count is less than 100
        //   * Yellow, 30px circles when point count is between 100 and 750
        //   * Pink, 40px circles when point count is greater than or equal to 750
        "circle-color": [
          "step",
          ["get", "point_count"],
          "#4c90e4",
          5,
          "#4c90e4",
          10,
          "#1470e1",
        ],
        "circle-radius": ["step", ["get", "point_count"], 20, 5, 30, 10, 40],
      },
    });

    map.addLayer({
      id: "cluster-count",
      type: "symbol",
      source: "mysource",
      filter: ["has", "point_count"],
      layout: {
        "text-field": "{point_count_abbreviated}",
        "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
        "text-size": 12,
      },
    });

    map.addLayer({
      id: "unclustered-point",
      type: "circle",
      source: "mysource",
      filter: ["!", ["has", "point_count"]],
      // TODO marker color configurable
      paint: {
        "circle-color": [
          "case",
          ["boolean", ["==", ["get", "type"], "hotline"], false],
          "#8c1c5c",
          "#4c90e4",
        ],
        "circle-radius": [
          "case",
          ["boolean", ["==", ["get", "type"], "hotline"], false],
          6,
          6,
        ],
        "circle-stroke-width": 1,
        "circle-stroke-color": "#fff",
      },
    });
  }); // on load

  map.on("click", "clusters", function (e) {
    $store_location[mapname] = null;
    var features = map.queryRenderedFeatures(e.point, {
      layers: ["clusters"],
    });
    var clusterId = features[0].properties.cluster_id;
    map
      .getSource("mysource")
      .getClusterExpansionZoom(clusterId, function (err, zoom) {
        if (err) return;

        map.easeTo({
          center: features[0].geometry.coordinates,
          zoom: zoom,
        });
      });
  });

  map.on("mouseenter", "clusters", function () {
    map.getCanvas().style.cursor = "pointer";
  });
  map.on("mouseleave", "clusters", function () {
    map.getCanvas().style.cursor = "";
  });

  map.on("click", "unclustered-point", function (e) {
    $store_location[mapname] = e.features[0];

    $store_searchstring_expert[mapname] = "";
    $store_expert[mapname] = null;
  });

  // on mouse over
  map.on("mouseenter", "unclustered-point", function (e) {
    map.getCanvas().style.cursor = "pointer";
    // Show popup
    let coordinates = e.features[0].geometry?.coordinates?.slice();
    let properties = e.features[0].properties;

    // Ensure that if the map is zoomed out such that multiple
    // copies of the feature are visible, the popup appears
    // over the copy being pointed to.
    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
      coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
    }

    let popup = $store_popup[mapname];
    popup
      .setLngLat(coordinates)
      .setHTML('<div id="mini-scroller"></div>')
      .addTo(map);
    new MiniScroller({
      target: popup.getElement().querySelector("#mini-scroller"),
      props: { location_properties: properties },
    });
  });

  map.on("mouseleave", "unclustered-point", function () {
    map.getCanvas().style.cursor = "";
    // Remove popup from map.
    $store_popup[mapname] &&
      !$store_location[mapname] &&
      $store_popup[mapname].remove();
  });

  const update_visible_locations = (region) => {
    let bounds = map.getBounds();
    let expert = $store_expert[mapname];
    let visible_locations = $store_locations_all[mapname].filter(
      (item) =>
        item.geometry?.coordinates &&
        item.geometry.coordinates.length === 2 &&
        bounds.contains(item.geometry.coordinates) &&
        ($store_searchstring_location[mapname]
          ? item.properties.name_d
              .toLowerCase()
              .includes($store_searchstring_location[mapname].toLowerCase())
          : true) &&
        (expert
          ? item.properties.experts
              .map((el) => el["@id"])
              .includes(expert["@id"])
          : true)
    );
    $store_locations_visible[mapname] = visible_locations;
  };
  map.on("moveend", function () {
    $store_map_bounds[mapname] = map.getBounds();
  });
  map.on("dragend", function () {
    $store_map_bounds[mapname] = map.getBounds();
  });
</script>
